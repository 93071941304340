import React, { useEffect , useState } from 'react';
import { projectFirestore} from './firebase/config';

const LoadDocs = props => {
  const { setImageDocsArray } = props

  const [docs, setdocs] = useState([])
  const [loading, setLoading] = useState(true);

  const getTagsAndTitle = (doc) => {
    let tagsAndTitle = {};

    tagsAndTitle.title = doc.comments.split('#')[0];
    tagsAndTitle.tags = doc.comments.substring(doc.comments.indexOf('#'));

    return tagsAndTitle;
  }


  useEffect(() => {

      const fetchData = async() => {

          try {


            const querySnapshot = await projectFirestore.collection("images").get();
            let documents =[];

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                documents.push({...doc.data() ,id: doc.id});
                //console.log(doc.id, " => ", doc.data());
              });

              let parsedDocuments =[];
              for (let i = 0 ; i < documents.length ; i++) {
                let tagsAndTitle = getTagsAndTitle(documents[i]);

                var rxDatePattern = /[0-9]{4}-[0-9]{2}-[0-9]{2}/i;
                var dtArray = documents[i].url.match(rxDatePattern); // is format OK?

                if (dtArray == null) 
                  continue;

                  var vals = dtArray[0].split('-');
                  var year = vals[0];
                  var month = vals[1];
                  var day = vals[2];

                  const theDate = day + '-' + month  + '-' + year;

                parsedDocuments.push({...documents[i] ,tagsAndTitle , theDate});

               
        
                //Checks for mm/dd/yyyy format.
                const dtMonth = dtArray[1];
                const dtDay= dtArray[3];
                const dtYear = dtArray[5];        
        
            
              }   
              setdocs(parsedDocuments);
              setImageDocsArray(parsedDocuments)

          } catch(err) {
              console.error(err);
          }

      };

      fetchData();

  }, []);

  return (
      <div>

          {!loading && docs !=  []}


      </div>
  )

};
export default LoadDocs;
