/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import MainText from './MainText'


const SliderContent = props => (
  <div id = "sliderContentId"
    css={css`
      transform: translateX(-${props.translate}px);
      transition: transform ease-out ${props.transition}s;
      height: 100%;
      width: ${props.width}px;
      display: flex;
    `}
  >
    <MainText activeSlide = {props.activeSlide}></MainText>

    {props.children}
  </div>
)

export default SliderContent
