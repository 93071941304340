//import firebase from 'firebase/app'
import firebase from 'firebase/compat/app';

import 'firebase/compat/storage';
import 'firebase/compat/firestore';


/*
var firebaseConfig = {
  apiKey: "AIzaSyDLmnoBvnjOQ-AaJgDw1DnVBiTETbcp8LI",
  authDomain: "the-net-ninja-sandbox.firebaseapp.com",
  databaseURL: "https://the-net-ninja-sandbox.firebaseio.com",
  projectId: "the-net-ninja-sandbox",
  storageBucket: "the-net-ninja-sandbox.appspot.com",
  messagingSenderId: "485942827092",
  appId: "1:485942827092:web:1811d9d8f1f5fabcd5b5c1"
}; */



const firebaseConfig = {
  apiKey: "AIzaSyDOhETPkUV4cCwXx2USqxc3o7OnWQQRHJ4",
  authDomain: "responcity-1c2ae.firebaseapp.com",
  projectId: "responcity-1c2ae",
  storageBucket: "responcity-1c2ae.appspot.com",
  messagingSenderId: "411731500049",
  appId: "1:411731500049:web:9503c463bd425ae50565b0",
  measurementId: "G-GVGL1Z8JEJ"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };