/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import './App.css';



/**
 * @function Thumbnail
 */
 function InstagramLogo()  {

  const handleClick = (url) => {
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
}

  return (
    
        <div className="instagramLogo">
          <div className="instagramLogoContainer" onClick={() => { handleClick("https://www.instagram.com/idigtelaviv/")}}>

            <span  className="instagramLogoImage"></span>
              
            <div className="InstagramHash">עירבעבודות#  </div>
          </div>
        </div>
  )
}

export default InstagramLogo


