/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'

const leftArrow = require('./left-arrow.png');
const rightArrow = require('./Right_Arrow_grey_60px-outline.svg');


const Arrow = ({ direction, handleClick }) => {


//  console.log("direction = " + direction);

  /*const [cursorUrl, setCursorUrl] = useState("");

  if (direction === 'right') {
    setCursorUrl("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'%0Aheight='60px' widt='60px' viewBox='0 0 64.4 64.7' enable-background='new 0 0 64.4 64.7' xml:space='preserve'%3E%3Cpolygon fill='%23D4D4D4' points='32,0 27.3,4.7 51.9,29 0.4,29 0.4,36 51.6,36 26.9,60.2 31.5,64.8 64.5,32.2 '/%3E%3C/svg%3E%0A");
    console.log("right");
  } {
    if (direction === 'left') {
      setCursorUrl("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'%0Aheight='60px' widt='60px' viewBox='0 0 64.4 64.7' enable-background='new 0 0 64.4 64.7' xml:space='preserve'%3E%3Cpolygon fill='%23D4D4D4' points='32.9,0 37.6,4.7 12.9,29 64.4,29 64.4,36 13.3,36 38,60.2 33.3,64.8 0.4,32.2  '/%3E%3C/svg%3E%0A");
      console.log("left");
    } 

    if (direction === 'up') {
      setCursorUrl("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'%0Aheight='60px' widt='60px' viewBox='0 0 64.4 64.7' enable-background='new 0 0 64.4 64.7' xml:space='preserve'%3E%3Cpolygon fill='%23D4D4D4' points='32.9,0 37.6,4.7 12.9,29 64.4,29 64.4,36 13.3,36 38,60.2 33.3,64.8 0.4,32.2  '/%3E%3C/svg%3E%0A");
      console.log("up");
    }  

  }*/
    return (
    <div
      onClick={handleClick}
      css={css`
      ${direction === 'right' ? `right: 0` : ``};
      ${direction === 'left' ? `left: 0` : ``};
      ${direction === 'up' ? `left: 0` : ``};
      ${direction === 'right' ? `cursor: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'%0Aheight='60px' widt='60px' viewBox='0 0 64.4 64.7' enable-background='new 0 0 64.4 64.7' xml:space='preserve'%3E%3Cpolygon fill='%23D4D4D4' points='32,0 27.3,4.7 51.9,29 0.4,29 0.4,36 51.6,36 26.9,60.2 31.5,64.8 64.5,32.2 '/%3E%3C/svg%3E%0A") , auto` : 
      ``};
      ${direction === 'left' ? `cursor: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'%0Aheight='60px' widt='60px' viewBox='0 0 64.4 64.7' enable-background='new 0 0 64.4 64.7' xml:space='preserve'%3E%3Cpolygon fill='%23D4D4D4' points='32.9,0 37.6,4.7 12.9,29 64.4,29 64.4,36 13.3,36 38,60.2 33.3,64.8 0.4,32.2  '/%3E%3C/svg%3E%0A") , auto` : 
      ``};
      ${direction === 'up' ? `cursor: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' %0Aheight='40px' widt='40px' viewBox='0 0 58.7 34.2' enable-background='new 0 0 58.7 34.2' xml:space='preserve'%3E%3Cg%3E%3Cpolygon fill='%23D4D4D4' points='29.1,0 58.7,30 54.4,34.2 29.1,8.6 4.3,33.9 0,29.7 '/%3E%3C/g%3E%3C/svg%3E%0A") 40 40 , auto` : 
      ``};
        top: 0;
        ${direction === 'up' ? `width: 100%` : `width: 50%`};
        ${direction === 'up' ? `height: calc(100vh)` : `height: calc(100vh - 3.25rem)`};
        position: absolute;
        z-index: 9990;
        text-indent: -9999px;
        overflow: hidden;

      `}
    >
    </div>
  )
}

export default Arrow
