/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import './App.css';


const logoImg1 = require('./img/logo-idigTelAviv-1.svg');
const logoImg2 = require('./img/logo-idigTelAviv-2.svg');
const logoImg3 = require('./img/logo-idigTelAviv-3.svg');
const logoImg4 = require('./img/logo-idigTelAviv-4.svg');
const logoImg5 = require('./img/logo-idigTelAviv-5.svg');


/**
 * @function Thumbnail
 */

 const LogoIdigTelAviv = props => {
  const { showAllImagesFunc } = props


  return (
    <div className="logoIdigTelAviv"
      onClick={() => { showAllImagesFunc()}}
      
    />

    
  )
}

export default LogoIdigTelAviv


