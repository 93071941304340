/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import Thumbnail from './Thumbnail'

const getWidth = () => window.innerWidth

/**
 * @function Thumbnails
 */
const Thumbnails = props => {
  const { thumbnails , thumbnailsCss , handleClick , thumbnailsIndexes} = props

  return (
    <div>
        {thumbnails.map((_thumbnail, i) => (
          <Thumbnail key={_thumbnail + i} content={_thumbnail} thumbnailCss={thumbnailsCss[i] } index={i} handleClick={handleClick} thumbnailIndex = {thumbnailsIndexes[i]}/>
        ))}

    </div>
  )
}

export default Thumbnails
