/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'

import './App.css';
// import firebase from "firebase";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import Slider from './Slider'
import LoadDocs from './LoadDocs'
//import useLoadDocs from './useLoadDocs';

import LogoIdigTelAviv from './LogoIdigTelAviv'
import LogoIdigTelAviv1 from './LogoIdigTelAviv1'
import ResponcityLogo from './ResponcityLogo'
import TaggleBottomStrip from './TaggleBottomStrip'
import ResponcityGallery from './ResponcityGallery'

function App() {


  const [showUpSlideVal, setShowUpSlide] = React.useState(false);
  const [pageYOffset, setPageYOffset] = React.useState(0);
  const [showAllImagesVal, setShowAllImages] = React.useState(false);
  const [imageDocsArray, setImageDocsArray] = useState([]);
  const [activeSlice, setActiveSlice] = React.useState(0);
  const [sliderHome, setSliderHome] = React.useState(false);
  const [loadDocsCompleted, setLoadDocsCompleted] = React.useState(false);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 800px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);


  useEffect(() => { setShowUpSlide(showUpSlideVal) }, [showUpSlideVal] );
  useEffect(() => { setPageYOffset(pageYOffset) }, [pageYOffset] );

  const showUpSlideFunc = (showUpSlideVal1) => {
    if (showUpSlideVal1 != showUpSlideVal) {
      console.log("showUpSlideVal " + showUpSlideVal);
      console.log("showUpSlideVal1 " + showUpSlideVal1);
    }
    setShowUpSlide(showUpSlideVal1);
  }

  const showAllImagesFunc = () => {
    setShowAllImages(!showAllImagesVal);
    setSliderHome(true);

  }

  const showNotAllImagesFunc = () => {
    setShowAllImages(false);
    setSliderHome(true);
  }

  useEffect(() => {
        
    window.addEventListener('scroll', scrollHandler);
 
    return () => window.removeEventListener('scroll', scrollHandler);
    
}, []);

const scrollHandler = () => {
  setPageYOffset(window.pageYOffset);
}


  if (!showAllImagesVal) {
    if (!showAllImagesVal && imageDocsArray.length == 0) {
      return (
        <div className="App">
          <LogoIdigTelAviv showAllImagesFunc={showAllImagesFunc}></LogoIdigTelAviv>
          <ResponcityLogo></ResponcityLogo>
          <LoadDocs setImageDocsArray={setImageDocsArray}></LoadDocs>

        </div>
      ); 
    } else {
      return (
        <div className="App">
          <LogoIdigTelAviv showAllImagesFunc={showAllImagesFunc}></LogoIdigTelAviv>
          <ResponcityLogo  showNotAllImagesFunc={showNotAllImagesFunc}> </ResponcityLogo> 
          <Slider slides={imageDocsArray} matches={matches} showUpSlideVal={showUpSlideVal} sliderHome={sliderHome} setSliderHome={setSliderHome} setActiveSlice={setActiveSlice}>
          </Slider>
          <TaggleBottomStrip showUpSlideFunc={showUpSlideFunc} pageYOffset={pageYOffset} activeSlice={activeSlice} matches={matches}></TaggleBottomStrip>

        </div>
      ); 

    }
  } else {
    return (
      <div className="App">
        <ResponcityGallery images={imageDocsArray}></ResponcityGallery>
        <ResponcityLogo  showNotAllImagesFunc={showNotAllImagesFunc}> </ResponcityLogo> 
        <LogoIdigTelAviv showAllImagesFunc={showAllImagesFunc}></LogoIdigTelAviv>
      </div>
    );
  }



} 

export default App;
