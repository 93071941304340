/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import './App.css';

/**
 * @function Thumbnail
 */
const Thumbnail = props => {
  const { content , thumbnailCss , handleClick , thumbnailIndex} = props
  const [filter, setFilter] = React.useState("blur(20px) saturate(150%)");

  return (
    <div className="thumbnail"
    
    onMouseOver={() => setFilter("blur(0) saturate(150%)")}
    onMouseLeave={() => setFilter("blur(20px) saturate(150%)")}
    onMouseEnter={() => setFilter("blur(0) saturate(150%)")}
 //   onClick={() => handleClick(thumbnailIndex)}
    onClick={() => { handleClick(thumbnailIndex)}}
      css={css`
      position: fixed;
      z-index: 9995;
      margin-top: -10%;
      margin-left: -10%;
      top: ${thumbnailCss.top};
      left: ${thumbnailCss.left};
      animation-duration: ${thumbnailCss.animationDuration};
      animation-name: ${thumbnailCss.animationName};
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      cursor: pointer;
      opacity: 1;
      filter: ${filter};

      `}
      
    >
      <img className="thumbnailImage" src={content.url}>
      </img> 

    </div>
  )
}

export default Thumbnail
