/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import './App.css';



/**
 * @function Thumbnail
 */
  const ResponcityLogo = props => {
    const { showNotAllImagesFunc} = props
  

  return (
    <div className="responcityLogo" onClick={() => { showNotAllImagesFunc()}}
    />
  )
}

export default ResponcityLogo


