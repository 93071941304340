/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import './App.css';

/**
 * @function MainText
 */
const MainText = props => {
  const { activeSlide} = props



  if (activeSlide == 0) {
    return (
      
      <div className='mainText'>

שתפו את החיים שלכם בתל-אביב יפו <br /> 
 מסביב  לעבודות ללא הפסקה

      </div>
    )} else  {
      return (null);
    }
        
  }

export default MainText




