/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import './App.css';


const logoImg1 = require('./img/logo-idigTelAviv-1.svg');
const logoImg2 = require('./img/logo-idigTelAviv-2.svg');
const logoImg3 = require('./img/logo-idigTelAviv-3.svg');
const logoImg4 = require('./img/logo-idigTelAviv-4.svg');
const logoImg5 = require('./img/logo-idigTelAviv-5.svg');


/**
 * @function Thumbnail
 */

 const LogoIdigTelAviv1 = props => {
  const { showAllImagesFunc } = props


  return (
    <div
      onClick={() => { showAllImagesFunc()}}
      css={css`
      position: fixed;
      z-index: 9999;
      width:440px;
      height: 56px;
      top: 34px;
      left: 2.7%;
      animation: changeBg 1000ms infinite;
      animation-timing-function: ease;
      cursor: pointer;
      opacity: 1;
      background-repeat: no-repeat;

      @keyframes changeBg{
        0%,100%  {background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 657.1 121.2' enable-background='new 0 0 657.1 121.2' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='%23FFFFFF' d='M15.1,85.4c1.9,0,4.2-2.3,4.2-4.2V36.4H10v-9.6h57.2V95h-9.6V36.4H29v46.7C29,88.6,22.5,95,17,95h-8v-9.6 H15.1z'/%3E%3Cpath fill='%23FFFFFF' d='M93.9,95V36.4h-9.1v-9.6h18.7V95H93.9z'/%3E%3Cpath fill='%23FFFFFF' d='M164.4,36.4V95h-9.6V36.4h-34.7v-9.6H172v9.6H164.4z'/%3E%3Cpath fill='%23FFFFFF' d='M197.4,95V36.4h-9.1v-9.6H207V95H197.4z'/%3E%3Cpath fill='%23FFFFFF' d='M225.3,95v-9.6h35v-49h-35v-9.6h44.3v58.6h6.9V95H225.3z'/%3E%3Cpath fill='%23FFFFFF' d='M333.9,26.8h9.9v53c0,7.1-8.1,15.2-15.2,15.2h-40.2v-9.6H305c-2.6-2.6-3.9-4.3-3.9-7.6v-51h9.6v58.6h17.7 c2.3,0,5.5-3.2,5.5-5.5V26.8z'/%3E%3Cpath fill='%23FFFFFF' d='M361.2,95v-9.6h35v-49h-35v-9.6h44.3v58.6h6.9V95H361.2z'/%3E%3Cpath fill='%23FFFFFF' d='M462.8,95V36.4h-35.3v-9.6h44.9V95H462.8z'/%3E%3Cpath fill='%23FFFFFF' d='M498.5,60.5V36.4h-10.5v-9.6h20.1v33.7H498.5z'/%3E%3Cpath fill='%23FFFFFF' d='M566.3,26.8h9.9v53c0,7.1-8.1,15.2-15.2,15.2h-40.2v-9.6h16.6c-2.6-2.6-3.9-4.3-3.9-7.6v-51h9.6v58.6h17.7 c2.3,0,5.5-3.2,5.5-5.5V26.8z'/%3E%3Cpath fill='%23FFFFFF' d='M594.5,45.3h20.1l5.7-19.1h8.5L623,45.3H637l5.7-19.1h8.5l-5.7,19.1h11.7v7.9h-14L639,67.2h18.2v7.9h-20.5 L630.7,95h-8.5l5.9-19.9h-13.9L608.3,95h-8.5l5.9-19.9h-11.2v-7.9h13.6l4.1-13.9h-17.7V45.3z M616.6,67.2h13.9l4.1-13.9h-13.9 L616.6,67.2z'/%3E%3C/g%3E%3C/svg%3E%0A");}
        20% {background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 657.1 121.2' enable-background='new 0 0 657.1 121.2' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='%23FFFFFF' d='M53.9,95.2V40H31.7v42.5c0,6-7,12.8-13,12.8H8V82.1h7.7c1.1,0,2.8-1.6,2.8-2.7V40h-8.4V26.9H67v68.3H53.9z' /%3E%3Cpath fill='%23FFFFFF' d='M89.9,95.2V40H81V26.9h22v68.3H89.9z'/%3E%3Cpath fill='%23FFFFFF' d='M164.3,40v55.2h-13.1V40h-33.5V26.9h53.5V40H164.3z'/%3E%3Cpath fill='%23FFFFFF' d='M194.7,95.2V40h-8.9V26.9h22v68.3H194.7z'/%3E%3Cpath fill='%23FFFFFF' d='M224.9,95.2V82.1h32.3V40h-32.3V26.9h45.4v55.2h6.1v13.1H224.9z'/%3E%3Cpath fill='%23FFFFFF' d='M329.4,95.2h-39.5V82.1h14.5l-2-2.2c-2-2.2-2.5-3.9-2.5-8v-45H313v55.2h15.9c1.3,0,3.5-2,3.5-3.4V26.9h13 v52.3C345.4,86.7,336.8,95.2,329.4,95.2z'/%3E%3Cpath fill='%23FFFFFF' d='M361.8,95.2V82.1h32.3V40h-32.3V26.9h45.4v55.2h6.1v13.1H361.8z'/%3E%3Cpath fill='%23FFFFFF' d='M458.2,95.2V40h-32.6V26.9h45.7v68.3H458.2z'/%3E%3Cpath fill='%23FFFFFF' d='M494.8,59.9V40H486V26.9h22v33H494.8z'/%3E%3Cpath fill='%23FFFFFF' d='M561.5,95.2H522V82.1h14.5l-2-2.2c-2-2.2-2.5-3.9-2.5-8v-45h13.1v55.2h15.9c1.3,0,3.5-2,3.5-3.4V26.9h13 v52.3C577.6,86.7,568.9,95.2,561.5,95.2z'/%3E%3Cpath fill='%23FFFFFF' d='M594,44.8H614l5.4-18.8h10.3l-5.4,18.8h11.6l5.5-18.8h10.3l-5.5,18.8h11.1v9.6h-13.8l-3.5,11.8h17.3v9.6 H637l-5.9,19.5h-10.3l5.8-19.5H615l-5.9,19.5h-10.3l5.8-19.5H594v-9.6h13.5l3.7-11.8H594V44.8z M617.8,66.2h11.6l3.7-11.8h-11.7 L617.8,66.2z'/%3E%3C/g%3E%3C/svg%3E%0A");}
        40% {background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 657.1 121.2' enable-background='new 0 0 657.1 121.2' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='%23FFFFFF' d='M13.9,86.9c2.5,0,5.3-2.8,5.3-5.3V34.8H9.6V27h56.6v67.9h-8v-60H27.1v48.8c0,5.1-6.1,11.2-11.2,11.2H8.6v-8 H13.9z'/%3E%3Cpath fill='%23FFFFFF' d='M94.1,94.8v-60h-9.4V27h17.3v67.9H94.1z'/%3E%3Cpath fill='%23FFFFFF' d='M163.1,34.8v60h-7.8v-60h-35.6V27H171v7.8H163.1z'/%3E%3Cpath fill='%23FFFFFF' d='M197.7,94.8v-60h-9.4V27h17.3v67.9H197.7z'/%3E%3Cpath fill='%23FFFFFF' d='M224.9,94.8v-8h35.8V34.8h-35.8V27h43.5v59.9h7v8H224.9z'/%3E%3Cpath fill='%23FFFFFF' d='M334.9,27h7.8v53.2c0,6.8-7.7,14.6-14.5,14.6H288v-8h16.6c-3.5-3-3.6-4.9-3.6-8.7V27h8v59.9h19.4 c2.7,0,6.6-3.7,6.6-6.6V27z'/%3E%3Cpath fill='%23FFFFFF' d='M360.9,94.8v-8h35.8V34.8h-35.8V27h43.5v59.9h7v8H360.9z'/%3E%3Cpath fill='%23FFFFFF' d='M463.4,94.8v-60h-36.2V27h44v67.9H463.4z'/%3E%3Cpath fill='%23FFFFFF' d='M498.7,59.5V34.8h-10.8V27h18.7v32.5H498.7z'/%3E%3Cpath fill='%23FFFFFF' d='M567.4,27h7.8v53.2c0,6.8-7.7,14.6-14.5,14.6h-40.2v-8h16.6c-3.5-3-3.6-4.9-3.6-8.7V27h8v59.9h19.4 c2.7,0,6.6-3.7,6.6-6.6V27z'/%3E%3Cpath fill='%23FFFFFF' d='M595,46h20.3l5.9-19.6h6.5L621.9,46h16.3l6-19.6h6.5L644.9,46h12.3v6.1H643l-4.8,16.2h19v6.1h-20.8l-6,20.4 h-6.6l6-20.4h-16.3l-6.1,20.4h-6.6l6-20.4H595v-6.1h13.7l4.8-16.2H595V46z M615.3,68.3h16.3l4.8-16.2h-16.3L615.3,68.3z'/%3E%3C/g%3E%3C/svg%3E%0A");}
        60% {background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 657.1 121.2' enable-background='new 0 0 657.1 121.2' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='%23FFFFFF' d='M45,95.2L54.7,40H32.5L25,82.4c-1.1,6-9.3,12.8-15.2,12.8H-0.9l2.3-13.1h7.7c1.1,0,3.1-1.6,3.3-2.7l7-39.4 H11l2.3-13.1h56.9l-12,68.3H45z'/%3E%3Cpath fill='%23FFFFFF' d='M81,95.2L90.8,40h-8.9l2.3-13.1h22l-12,68.3H81z'/%3E%3Cpath fill='%23FFFFFF' d='M165.2,40l-9.7,55.2h-13.1l9.7-55.2h-33.5l2.3-13.1h53.5L172.1,40H165.2z'/%3E%3Cpath fill='%23FFFFFF' d='M185.9,95.2l9.7-55.2h-8.9l2.3-13.1h22l-12,68.3H185.9z'/%3E%3Cpath fill='%23FFFFFF' d='M216,95.2l2.3-13.1h32.3l7.4-42.1h-32.3l2.3-13.1h45.4l-9.7,55.2h6.1l-2.3,13.1H216z'/%3E%3Cpath fill='%23FFFFFF' d='M320.5,95.2H281l2.3-13.1h14.5l-1.6-2.2c-1.6-2.2-1.8-3.9-1.1-8l7.9-45h13.1l-9.7,55.2h15.9 c1.3,0,3.9-2,4.1-3.4l9.1-51.8h13l-9.2,52.3C338.1,86.7,327.9,95.2,320.5,95.2z'/%3E%3Cpath fill='%23FFFFFF' d='M352.9,95.2l2.3-13.1h32.3L395,40h-32.3l2.3-13.1h45.4l-9.7,55.2h6.1l-2.3,13.1H352.9z'/%3E%3Cpath fill='%23FFFFFF' d='M449.3,95.2l9.7-55.2h-32.6l2.3-13.1h45.7l-12,68.3H449.3z'/%3E%3Cpath fill='%23FFFFFF' d='M492.2,59.9l3.5-19.9h-8.9l2.3-13.1h22l-5.8,33H492.2z'/%3E%3Cpath fill='%23FFFFFF' d='M552.7,95.2h-39.5l2.3-13.1h14.5l-1.6-2.2c-1.6-2.2-1.8-3.9-1.1-8l7.9-45h13.1l-9.7,55.2h15.9 c1.3,0,3.9-2,4.1-3.4l9.1-51.8h13l-9.2,52.3C570.2,86.7,560,95.2,552.7,95.2z'/%3E%3Cpath fill='%23FFFFFF' d='M594,44.8H614l8.7-18.8H633l-8.7,18.8h11.6l8.9-18.8h10.3l-8.9,18.8h11.1l-1.7,9.6h-13.8L636,66.2h17.3 l-1.7,9.6h-20.2l-9.3,19.5h-10.3l9.2-19.5h-11.6l-9.3,19.5H590l9.2-19.5h-10.6l1.7-9.6h13.5l5.7-11.8h-17.1L594,44.8z M614.1,66.2 h11.6l5.7-11.8h-11.7L614.1,66.2z'/%3E%3C/g%3E%3C/svg%3E%0A");}
        80% {background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 657.1 121.2' enable-background='new 0 0 657.1 121.2' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='%23FFFFFF' d='M44.6,95.9V45H29.4V81c0,7.4-7.4,15-15,15H0.2V78.5h9.1c0.5,0,1.1-0.6,1.1-1.1V45H2V27.6h61.5v68.3H44.6z' /%3E%3Cpath fill='%23FFFFFF' d='M81.1,95.9V45h-7.7V27.6H100v68.3H81.1z'/%3E%3Cpath fill='%23FFFFFF' d='M157.8,45v50.9h-19V45h-29.4V27.6h55.2V45H157.8z'/%3E%3Cpath fill='%23FFFFFF' d='M182.5,95.9V45h-7.7V27.6h26.7v68.3H182.5z'/%3E%3Cpath fill='%23FFFFFF' d='M212.7,95.9V78.5h30V45h-30V27.6h48.9v50.9h6.1v17.4H212.7z'/%3E%3Cpath fill='%23FFFFFF' d='M317.5,95.9h-42.1V78.5h15l-2.6-4c-1.2-1.9-2.3-4.7-2.3-7V27.6h19v50.9h11.9c0.5,0,1.1-0.6,1.1-1.1V27.6 h18.8v49.6C336.2,86.5,327,95.9,317.5,95.9z'/%3E%3Cpath fill='%23FFFFFF' d='M349.2,95.9V78.5h30V45h-30V27.6h48.9v50.9h6.1v17.4H349.2z'/%3E%3Cpath fill='%23FFFFFF' d='M445.2,95.9V45h-32.1V27.6h51v68.3H445.2z'/%3E%3Cpath fill='%23FFFFFF' d='M483.1,60.6V45h-8.9V27.6H502v33H483.1z'/%3E%3Cpath fill='%23FFFFFF' d='M551.9,95.9h-42.1V78.5h15l-2.6-4c-1.2-1.9-2.3-4.7-2.3-7V27.6h19v50.9h11.9c0.5,0,1.1-0.6,1.1-1.1V27.6 h18.8v49.6C570.6,86.5,561.4,95.9,551.9,95.9z'/%3E%3Cpath fill='%23FFFFFF' d='M627.2,44.6l5.2-17.6h12.5l-5.2,17.6h10.4v11.7h-13.9l-2.6,9.1h16.5v11.7H630l-5.5,18.9h-12.5l5.5-18.9 h-11.2l-5.5,18.9h-12.5l5.5-18.9H584V65.4h13.3l2.6-9.1H584V44.6h19.5l5.2-17.6h12.5L616,44.6H627.2z M623.6,56.3h-11.2l-2.6,9.1 H621L623.6,56.3z'/%3E%3C/g%3E%3C/svg%3E%0A");}
      }
      

      `}
      
    />

    
  )
}

export default LogoIdigTelAviv1


