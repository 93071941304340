/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useMediaQuery } from 'react-responsive'

/**
 * @function ResponcityGallery
 */
const ResponcityGallery = props => {
  const { images } = props

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })


  const handleClick = (url) => {
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
}


    return (
      <div className="GalleryCSS" id="galleryId">

        <div className="row" >
            {images.map((_image, i) => (
                  <div css={css`
                  ${i % 4 == 0 ? `clear: both` : ``};`}>
      
          
              <div className="column">
                  <img className="galleryImage" src={_image.url} onClick={() => { handleClick(_image.instaUrl)}}/>
                  <div className="galleryTitle"> {_image.tagsAndTitle.title}  </div>
                  <div className="galleryTags"> {_image.tagsAndTitle.tags}  </div>
                  <div className="galleryTags galleryDate"> {_image.theDate}  </div>
                  
              </div>


              </div>
            ))}

      </div>
    </div>
    );
}


export default ResponcityGallery




