/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css, jsx } from '@emotion/react'
import InstagramLogo from './InstagramLogo'

import './App.css';


/**
 * @function TaggleBottomStrip
 */

 const TaggleBottomStrip = props => {
    const { showUpSlideFunc  , pageYOffset , activeSlice , matches} = props
  

    const [opacity, setOpacity] = React.useState(0);
    const [opacity1, setOpacity1] = React.useState(0);
    const [display, setDisplayText] = React.useState("none");
    const [textColor, setTextColor] = React.useState("white");
    const [_pageYOffset, setPageYOffset] = React.useState(pageYOffset);
    

     const textRef = useRef()

     useEffect(() =>  { 
            if (activeSlice == 0) {
                setOpacity(1);
                setOpacity1(1);
            } else {
                setOpacity(0);
            }

        } , [activeSlice] )

    useEffect(() =>  {setPageYOffset(pageYOffset)} , [pageYOffset] )

    useEffect(() => {
        
        const rect = textRef.current.getBoundingClientRect();
        if (rect.height == 0) {
            showUpSlideFunc(false);
            console.log(`Hidden element is NOT visible`); 
        } else {
            const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        
            const percentVisible = 1;
            let v1 = Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100));
            let v2 = Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100);

            if (!(v1 < percentVisible || v2 < percentVisible)) {
                showUpSlideFunc(true);
                console.log(`Hidden element is now visible`);

            } else {
                showUpSlideFunc(false);
                console.log(`Hidden element is NOT visible`);
            }
        }
    

    }, [pageYOffset]);


   /* useEffect(() => {
        
        setRef(textRef);
        
    }, []); */

    const handleClick = () => {
        setOpacity(1);
        setOpacity1(1);

        setDisplayText("block");
        window.location.href="#taggleBottomStripId";
    }

  return (

    <div>
        <div  className="TaggleBottomStripContainer"

            onMouseOver={() => {matches && setOpacity(1) ; matches && setOpacity1(1)}}
            onMouseLeave={() => matches && setOpacity(0)}
            onMouseEnter={() => matches && setOpacity(1)}
            onClick={() => { handleClick()}}
            onMouseDown={() => { !matches && setOpacity(1) ; !matches && setOpacity1(1) ; !matches && setTextColor("#ff7f00")}}
            onTouchStart={() => { !matches && setOpacity(1) ; !matches && setOpacity1(1) ; !matches && setTextColor("#ff7f00")}}  
            onMouseUp={() => {  !matches && setTextColor("white")}}
            onTouchEnd={() => {  !matches && setTextColor("white")}}
            

            css={css` opacity: ${opacity}; `}>

            <div css={css`opacity: ${opacity1} ; color: ${textColor};  `}> 
                <span  className="textUnderline"> קול קורא</span>   #עיר בעבודות 
            </div> 
        </div>


        <div  id="taggleBottomStripId"  ref={textRef} className="bottomStripContainer" css={css`display: ${display}; `} >

            <div className="BottomStripRightCol">
                <div className="BottomStripHeaderContainer">
                    <div>
                        קול קורא 
                    </div>
                    <div>
                    עיר בעבודות#
                    </div>
                </div>
                {matches && <InstagramLogo></InstagramLogo>}
                <div className="clearAll"></div>
            </div>

            <div  className="BottomStripLeftCol">
                <div class="paragraph">
                שתפו את החיים שלכם בתל אביב-יפו מסביב לעבודות ללא הפסקה!
                </div>
                <div className="paragraph">
                רספונסיטי (ResponCity), מעבדת העיר ההתנהגותית של עיריית תל אביב-יפו, משיקה אלבום תיעוד שיתופי של החיים בעיר בתקופות עבודות הרכבת הקלה ׳#עיר בעבודות׳.
                </div>

                <div className="paragraph">
                בכל רגע נתון הרחובות והחיים משתנים: חפירות, גדרות, חסימות, קידוחים ושינוי מסלולים. הרפתקה עירונית גדולה! איך זה משפיע עליכם? שתפו את החוויה האישית היומיומית שלכם בעיר בתקופה מאתגרת זו דרך תמונות באינסטגרם. תעדו את החיים שלכם בתל אביב-יפו מסביב לשעון, מסביב לעבודות ללא הפסקה.
                </div>
                <div className="paragraph">

                פרסמו את התמונות שלכם באינסטגרם באמצעות הַאשְׁטָאג  ׳#עירבעבודות׳. ניתן גם לחזור ולתייג תמונות שכבר צולמו ופורסמו, להוספה לסיפור המתגלגל של העיר המתהפכת!
                </div>

                {!matches && <InstagramLogo></InstagramLogo>}

                <div className="remarks">
            <div>
                * התיוג מהווה אישור להצגת התמונות בגלריית #עירבעבודות׳. כל שימוש אחר בתוכן יעשה באישור המשתמש.ת בלבד. 
                </div>
                <div>
                * העירייה רשאית שלא לפרסם תוכן פוגעני/לא רלוונטי לפי שיקול דעתה
                </div>

            </div>

            </div>
            <div className="clearAllAll"></div>

        </div>

    </div>
    
  )
}

export default TaggleBottomStrip


